import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Burger from '../../components/Burger/Burger';
import BuildControls from '../../components/Burger/BuildControls/BuildControls';
import Modal from '../../components/UI/Modal/Modal';
import OrderSummary from '../../components/Burger/OrderSummary/OrderSummary';
import Spinner from '../../components/UI/Spinner/Spinner';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../axios-orders';
import {
  addIngredient,
  initIngredients,
  purchaseInit,
  removeIngredient,
  setAuthRedirectPath
} from '../../store/actions';

class BurgerBuilder extends Component {
  state = {
    purchasing: false
  };

  componentDidMount(){
    this.props.onInitIngredients();
  }

  updatePurchaseState = ingredients => {
    const sum = Object.keys(ingredients)
    .map(key => {
      return ingredients[key];
    })
    .reduce(( sum, el ) => {
      return sum + el;
    }, 0);
    return sum > 0;
  };

  purchaseHandler = () => {
    if (this.props.isAuthenticated) {
      this.setState({ purchasing: true });
    } else {
      this.props.onSetAuthRedirectPath('/checkout');
      this.props.history.push('/auth');
    }
  };

  purchaseCancelHandler = () => {
    this.setState({ purchasing: false });
  };

  purchaseContinueHandler = () => {
    this.props.onInitPurchase();
    this.props.history.push('/checkout');
  };

  render(){
    const disabledInfo = {
      ...this.props.ings
    };
    for (let key in disabledInfo) {
      disabledInfo[key] = disabledInfo[key] <= 0;
    }
    let orderSummary = null;

    let burger = this.props.error ? <p>Ingredients can&apos;t be loaded!</p> : <Spinner/>;
    if (this.props.ings) {
      burger = (
        <Fragment>
          <Burger ingredients={ this.props.ings }/>
          <BuildControls
            ingredientAdded={ this.props.onIngredientAdded }
            ingredientRemoved={ this.props.onIngredientRemoved }
            disabled={ disabledInfo }
            purchasable={ this.updatePurchaseState(this.props.ings) }
            ordered={ this.purchaseHandler }
            isAuth={ this.props.isAuthenticated }
            price={ this.props.price }/>
        </Fragment>
      );
      orderSummary = <OrderSummary
        ingredients={ this.props.ings }
        price={ this.props.price }
        purchaseCancelled={ this.purchaseCancelHandler }
        purchaseContinued={ this.purchaseContinueHandler }/>;
    }
    return (
      <Fragment>
        <Modal show={ this.state.purchasing } modalClosed={ this.purchaseCancelHandler }>
          { orderSummary }
        </Modal>
        { burger }
      </Fragment>
    );
  }
}

BurgerBuilder.propTypes = {
  history: PropTypes.object,

  onIngredientAdded: PropTypes.func,
  onIngredientRemoved: PropTypes.func,
  onInitIngredients: PropTypes.func,
  onInitPurchase: PropTypes.func,
  onSetAuthRedirectPath: PropTypes.func,

  ings: PropTypes.object,
  price: PropTypes.number,
  error: PropTypes.bool,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    ings: state.burgerBuilder.ingredients,
    price: state.burgerBuilder.totalPrice,
    error: state.burgerBuilder.error,
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onIngredientAdded: ingName => dispatch(addIngredient(ingName)),
    onIngredientRemoved: ingName => dispatch(removeIngredient(ingName)),
    onInitIngredients: () => dispatch(initIngredients()),
    onInitPurchase: () => dispatch(purchaseInit()),
    onSetAuthRedirectPath: path => dispatch(setAuthRedirectPath(path))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(BurgerBuilder, axios));
