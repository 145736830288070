import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';

import classes from './SideDrawer.module.scss';
import Backdrop from '../../UI/Backdrop/Backdrop';

const SideDrawer = props => {
  let attachedClasses = [classes.SideDrawer, classes.Close];
  if (props.open) {
    attachedClasses = [classes.SideDrawer, classes.Open];
  }
  return (
    <Fragment>
      <Backdrop show={ props.open } clicked={ props.closed }/>
      <div className={ attachedClasses.join(' ') }>
        <div className={ classes.Logo }>
          <Logo/>
        </div>
        <nav>
          <NavigationItems isAuthenticated={ props.isAuth }/>
        </nav>
      </div>
    </Fragment>
  );
};

SideDrawer.propTypes = {
  closed: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool
};

export default SideDrawer;
