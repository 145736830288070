import React from 'react';
import PropTypes from 'prop-types';

import s from './Input.module.scss';

const Input = props => {
  let inputElement = null;
  const inputClasses = [ s.InputElement ];

  if (props.invalid && props.shouldValidate && props.touched) {
    inputClasses.push(s.Invalid);
  }

  switch (props.elementType) {
    case ('input'):
      inputElement = <input
        className={ inputClasses.join(' ') }
        { ...props.elementConfig }
        value={ props.value }
        onChange={ props.changed }/>;
      break;
    case ('textarea'):
      inputElement = <textarea
        className={ inputClasses.join(' ') }
        { ...props.elementConfig }
        value={ props.value }
        onChange={ props.changed }/>;
      break;
    case ('select'):
      inputElement = (
        <select
          className={ inputClasses.join(' ') }
          value={ props.value }
          onChange={ props.changed }>
          { props.elementConfig.options.map(option => (
            <option
              value={ option.value }
              key={ option.value }>
              { option.displayValue }
            </option>
          )) }
        </select>);
      break;
  }

  return (
    <div className={ s.Input }>
      <label className={ s.Label }>{ props.label }</label>
      { inputElement }
    </div>
  );
};

Input.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  elementType: PropTypes.oneOf(['input', 'textarea', 'select']).isRequired,
  elementConfig: PropTypes.object,
  changed: PropTypes.func,
  invalid: PropTypes.bool,
  shouldValidate: PropTypes.object,
  touched: PropTypes.any
};

export default Input;
