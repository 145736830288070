import React, { Component, Fragment } from 'react';
import Modal from '../../components/UI/Modal/Modal';

/**
 * Показывает модалку с ошибкой при ошибках в апихе
 * @param WrappedComponent
 * @param axios
 */
const withErrorHandler = ( WrappedComponent, axios ) => {
  return class C extends Component {
    state = {
      error: null
    }

    componentWillMount(){
      this.reqInteceptor = axios.interceptors.request.use(request => {
        this.setState({ error: null });
        return request;
      });

      this.resInteceptor = axios.interceptors.response.use(res => res, error => {
        this.setState({ error: error });
      });
    }

    componentWillUnmount(){
      axios.interceptors.request.eject(this.reqInteceptor);
      axios.interceptors.response.eject(this.resInteceptor);
    }

    errorConfirmedHandler = () => {
      this.setState({ error: null });
    }

    render(){
      return (
        <Fragment>
          <Modal show={ !!this.state.error } modalClosed={ this.errorConfirmedHandler }>
            { this.state.error ? this.state.error.message : null }
          </Modal>
          <WrappedComponent { ...this.props } />
        </Fragment>
      );
    }
  };
};

export default withErrorHandler;
