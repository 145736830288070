import React from 'react';
import PropTypes from 'prop-types';

import classes from './NavigationItems.module.scss';
import NavigationItem from './NavigationItem/NavigationItem';

const NavigationItems = props => (
  <ul className={ classes.NavigationItems }>
    <NavigationItem link='/' exact>Burger Builder</NavigationItem>
    { props.isAuthenticated ? <NavigationItem link='/orders'>Orders</NavigationItem> : null }
    { props.isAuthenticated
      ? <NavigationItem link='/logout'>Logout</NavigationItem>
      : <NavigationItem link='/auth'>Authenticate</NavigationItem> }
  </ul>
);

NavigationItems.propTypes = {
  isAuthenticated: PropTypes.bool
};

export default NavigationItems;
