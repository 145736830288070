import React from 'react';
import PropTypes from 'prop-types';

import classes from './Button.module.scss';

const Button = props => (
  <button
    disabled={props.disabled}
    className={ [classes.Button, classes[props.btnType]].join(' ') }
    onClick={ props.clicked }>
    { props.children }
  </button>
);

Button.propTypes = {
  children: PropTypes.string.isRequired,
  btnType: PropTypes.oneOf(['Success', 'Danger']).isRequired,
  clicked: PropTypes.func,
  disabled: PropTypes.bool
};

export default Button;
