import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../UI/Button/Button';

class OrderSummary extends Component {

  render()
  {
    const ingredientSummary = Object.keys(this.props.ingredients)
      .map(igkey => {
        return (
          <li key={ igkey }>
            <span style={ { textTransform: 'capitalize' } }>{ igkey }: { this.props.ingredients[igkey] }</span>
          </li>
        );
      });
    return (
      <Fragment>
        <h3>Your Order</h3>
        <p>A delicious burger with the following ingredients:</p>
        <ul>
          { ingredientSummary }
        </ul>
        <p><strong>Total Price: { this.props.price.toFixed(2) }</strong></p>
        <p>Continue to Checkout?</p>
        <Button btnType={ 'Success' } clicked={ this.props.purchaseContinued }>CONTINUE</Button>
        <Button btnType={ 'Danger' } clicked={ this.props.purchaseCancelled }>CANCEL</Button>
      </Fragment>
    );
  }
}

OrderSummary.propTypes = {
  ingredients: PropTypes.object.isRequired,
  purchaseCancelled: PropTypes.func.isRequired,
  purchaseContinued: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired
};

export default OrderSummary;
