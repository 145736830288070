import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import CheckoutSummary from '../../components/Order/CheckoutSummary/CheckoutSummary';
import ContactData from './ContactData/ContactData';
import { purchaseInit } from '../../store/actions';

class Checkout extends Component {

  checkoutCancelHandler = () => {
    this.props.history.goBack();
  };

  checkoutContinuedHandler = () => {
    this.props.history.replace('/checkout/contact-data');
  };

  render(){
    let summary = <Redirect to="/"/>;
    if (this.props.ings) {
      const purchasedRedirect = this.props.purchased ?  <Redirect to='/' /> : null;
      summary =
        <div>
          { purchasedRedirect }
          <CheckoutSummary
            ingredients={ this.props.ings }
            checkoutCancelled={ this.checkoutCancelHandler }
            checkoutContinued={ this.checkoutContinuedHandler }/>
          <Route
            path={ this.props.match.path + '/contact-data' }
            component={ ContactData }/>
        </div>;
    }
    return summary;
  }
}

Checkout.propTypes = {
  // Редух
  ings: PropTypes.object,
  onInitPurchase: PropTypes.func,
  purchased: PropTypes.bool,

  // Роутер
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object
};

const mapStateToProps = state => {
  return {
    ings: state.burgerBuilder.ingredients,
    purchased: state.order.purchased
  };
};

export default connect(mapStateToProps)(Checkout);
