import React from 'react';
import PropTypes from 'prop-types';

import s from './Order.module.scss';

const Order = props => {
  const ingredients = [];

  for (let ingredientName in props.ingredients) {
    ingredients.push({
      name: ingredientName,
      amount: props.ingredients[ingredientName]
    });
  }

  const ingredientOutput = ingredients.map(ig => {
    return <span
      style={{
        textTransform: 'capitalize',
        display: 'inline-block',
        margin: '0 8px',
        border: '1px solid #ccc',
        padding: '5px'
      }}
      key={ ig.name }>{ ig.name } ({ ig.amount })</span>;
  });

  return (
    <div className={ s.Order }>
      <p>Ingredients: { ingredientOutput }</p>
      <p>Price: <strong>USD { props.price.toFixed(2) } </strong></p>
    </div>
  );
};

Order.propTypes = {
  price: PropTypes.number.isRequired,
  ingredients: PropTypes.object.isRequired
};

export default Order;
