import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from './hoc/Layout/Layout';
import BurgerBuilder from './containers/BurgerBuilder/BurgerBuilder';
import Checkout from './containers/Checkout/Checkout';
import Orders from './containers/Orders/Orders';
import Auth from './containers/Auth/Auth';
import Logout from './containers/Auth/Logout/Logout';
import { authCheckState } from './store/actions';


class App extends Component {
  componentDidMount(){
    this.props.onTryAutoSignup();
  }

  render(){
    let routes = (
      <Switch>
        <Route path='/auth' component={ Auth }/>
        <Route path='/' exact component={ BurgerBuilder }/>
        <Redirect to='/' />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route path='/checkout' component={ Checkout }/>
          <Route path='/orders' component={ Orders }/>
          <Route path='/auth' component={ Auth }/>
          <Route path='/logout' component={ Logout }/>
          <Route path='/' exact component={ BurgerBuilder }/>
          <Redirect to='/' />
        </Switch>
      );
    }
    return (
      <div>
        <Layout>
          { routes }
        </Layout>
      </div>
    );
  };
}

App.propTypes = {
  onTryAutoSignup: PropTypes.func,

  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(authCheckState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
